
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { PositionContainerType } from '../core-wallets/positionContainerType.types';
import { DataIntegrationStatus } from './DataIntegrationStatus';

export class DataIntegrationDto extends LogicalDeleteDTO {

  id: number;
  dataIntegrationName: string;
  dataIntegrationIconUrl: string;
  dataIntegrationGuideUrl: string;
  dataIntegrationMessage: string;
  dataIntegrationMessageCsv: string;
  dataIntegrationBeta: boolean;
  dataIntegrationIsCSV: boolean;
  dataIntegrationIsAPI: boolean;
  dataIntegrationKeyExpire: boolean;
  dataIntegrationType: PositionContainerType;
  dataIntegrationStatus: DataIntegrationStatus;
  order: number;
  keyList: string[];

  constructor() {
    super();
    this.id = undefined;
    this.dataIntegrationName = '';
    this.dataIntegrationIconUrl = '';
    this.dataIntegrationGuideUrl = '';
    this.dataIntegrationMessage = '';
    this.dataIntegrationMessageCsv = '';
    this.dataIntegrationBeta = false;
    this.dataIntegrationIsAPI = true;
    this.dataIntegrationIsCSV = false;
    this.dataIntegrationKeyExpire = false;
    this.dataIntegrationType = undefined;
    this.dataIntegrationStatus = undefined;
    this.order = undefined;
    this.keyList = [];
  }
}
