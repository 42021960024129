/* eslint-disable @typescript-eslint/naming-convention */
export enum BlockchainType {
    AKASH = 'Akash',
    ALGORAND = 'Algorand',
    AOK = 'AOK',
    APE = 'ApeChain',
    APTOS = 'Aptos',
    ARBITRUM = 'Arbitrum',
    ARBITRUM_NOVA = 'Arbitrum Nova',
    ARCHWAY = 'Archway',
    ARDOR = 'Ardor',
    ASTAR = 'Astar',
    AURORA = 'Aurora',
    AVALANCHE = 'Avalanche',
    AXELAR = 'Axelar',
    BAND = 'Band',
    BASE = 'Base',
    BEAM = 'Beam',
    BINANCECOIN = 'Binancecoin',
    BINANCE_BEACON_CHAIN = 'Binance beacon chain',
    BITCICOIN = 'Bitcichain',
    BITCOIN = 'Bitcoin',
    BITCOIN_ABC = 'Bitcoin ABC',
    BITCOIN_CASH = 'Bitcoin Cash',
    BITCOIN_SV = 'Bitcoin SV',
    BITDIVER = 'BitDiver',
    BITGERT = 'Bitgert',
    BITKUB = 'Bitkub',
    BITSHARES = 'Bitshares',
    BITTORRENT = 'Bittorrent',
    BLAST = 'Blast',
    BOBA = 'Boba',
    BXH = 'BXH',
    CALLISTO = 'Callisto',
    CANTO = 'Canto',
    CARDANO = 'Cardano',
    CELER = 'Celer Network',
    CELESTIA = 'Celestia',
    CELO = 'Celo',
    CHILIZ = 'Chiliz',
    CLOUDVERSE = 'Cloudverse',
    COINEX_CHAIN = 'Coinex Smart Chain',
    CONFLUX = 'Conflux',
    CORE = 'Core',
    COREUM = 'Coreum',
    COSMOS = 'Cosmos',
    CRYPTO_ORG = 'Cronos POS',
    CRONOS = 'Cronos',
    CUBE = 'CUBE',
    DASH = 'Dash',
    DEFI_KINGDOM = 'Defi Kingdom Blockchain',
    DEXALOT = 'Dexalot',
    DOGECHAIN = 'Dogechain',
    DOGECOIN = 'Dogecoin',
    DYMENSION ='Dymension',
    ELASTOS = 'Elastos',
    ELROND = 'Elrond',
    EMPIRE = 'Empire',
    ENERGI = 'Energi',
    EOS = 'EOS',
    ETHEREUM = 'Ethereum',
    ETHEREUM_CLASSIC = 'Ethereum Classic',
    ETHEREUM_POW = 'Ethereum PoW',
    EVERSCALE = 'Everscale',
    EVMOS = 'Evmos',
    EXOSAMA = 'Exosama',
    FACTOM = 'Factom',
    FANTOM = 'Fantom',
    FETCHAI = 'Asi Alliance',
    FINDORA = 'Findora',
    FLARE = 'Flare',
    FRAXTAL ='fraxtal',
    FUNCTIONX = 'FUNCTION X',
    FUSE = 'Fuse',
    FUSION = 'Fusion network',
    GNOSIS = 'Gnosis',
    GOCHAIN = 'Gochain',
    GODWOKEN = 'Godwoken',
    GREEN_DOT = 'Green Dot',
    GROESTLCOIN = 'Groestlcoin',
    HARMONY_ONE = 'Harmony One',
    HEDERA = 'Hedera Hashgraph',
    HOO = 'HOO',
    HUMANS = 'Humans.AI',
    HUOBI = 'HTX',
    HYDRA = 'Hydra',
    ICON = 'Icon',
    IMMUTABLE ='Immutable zkEVM',
    IOTEX = 'IoTEX',
    KARDIACHAIN = 'Kardiachain',
    KARURA = 'Karura',
    KAVA = 'Kava',
    KLAYTN = 'klaytn',
    KOMODO = 'Komodo',
    KROMA = 'Kroma',
    KUCOIN_CHAIN = 'Kucoin Chain',
    KUJIRA = 'Kujira',
    KUSAMA = 'Kusama',
    LINEA = 'Linea',
    LISK ='Lisk',
    LITECOIN = 'Litecoin',
    MANTLE = 'Mantle Network',
    MANTRA= 'Mantra',
    MELD = 'MELD',
    MEMES = 'Proof of Memes',
    METER = 'Meter',
    METIS = 'Metis Andromeda',
    MILKOMEDA = 'Milkomeda Cardano',
    MINTARA = 'Mintara',
    MIXIN = 'Mixin',
    MONERO = 'Monero',
    MOONBEAM = 'Moonbeam',
    MOONRIVER = 'Moonriver',
    NEAR = 'Near Protocol',
    NEM = 'Nem',
    NEO = 'NEO',
    NEON = 'Neon EVM',
    NULS = 'Nuls',
    OASIS = 'Oasis',
    OASYS = 'Oasys',
    OKXCOIN = 'Okx chain',
    OMNI = 'Omni',
    ONTOLOGY = 'Ontology',
    ONUS = 'Onus',
    OPBNB ='opBNB Mainnet',
    OPTIMISM = 'Optimism',
    ORDINALS = 'Ordinals',
    OSMOSIS = 'Osmosis',
    POLKADOT = 'Polkadot',
    POLYGON = 'Polygon',
    POLYGON_ZKEVM = 'Polygon zkEVM',
    PULSECHAIN = 'Pulsechain',
    QTUM = 'Qtum',
    RIPPLE = 'Ripple',
    ROLLUX = 'Rollux',
    RONIN = 'Ronin',
    ROOTSTOCK = 'Rootstock',
    SCROLL ='Scroll',
    SECRET = 'Secret',
    SHRAPNEL = 'Sharpnel',
    SHIDEN = 'Siden Network',
    SKALE = 'Skale',
    SMARTBCH = 'SmartBCH',
    SOLANA = 'Solana',
    SONGBIRD = 'Songbird',
    SORA = 'Sora',
    STACKS = 'Stacks',
    STELLAR = 'Stellar',
    STEP = 'Step',
    STRATSIS = 'Stratsis',
    STRIDE = 'Stride',
    SUI = 'Sui',
    SX = 'Sx',
    SYSCOIN = 'Syscoin',
    TAIKO = 'Taiko',
    TELOS = 'Telos',
    TENET = 'Tenet',
    TERRA = 'Terra',
    TEZOS = 'Tezos',
    THETA = 'Theta',
    THOR = 'Thor Chain',
    THUNDERCORE = 'Thundercore',
    TOMB = 'Tombchain',
    TOMOCHAIN = 'Tomochain',
    TRON = 'Tron',
    TRUSTLESS_COMPUTER = 'Trustless computer',
    UPTN = 'UPTN',
    VECHAIN = 'VECHAIN',
    VELAS = 'Velas',
    VITE = 'Vite',
    WANCHAIN = 'Wanchain',
    WAVES = 'WAVES',
    WEMIX = 'Wemix Network',
    WORLDCHAIN='World Chain',
    XAI = 'Xai',
    XDC = 'XDC',
    ZCASH = 'Zcash',
    ZETA = 'Zeta Chain',
    ZILLIQA = 'Zilliqa',
    ZKSYNC_ERA = 'Zksync Era',
    ZKSYNC_LITE = 'Zksync Lite',
    TON = 'Ton'
}

export const blockchainTypes = Object.keys(BlockchainType).sort();
