<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Lista delle crypto SCAM e FAKE</h2>
      <p>Imposta il filtro di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
  
        <mat-form-field>
          <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
        </mat-form-field>
  
        <mat-form-field>
          <mat-select [(ngModel)]="parameters.blockchainType" placeholder="Built on blockchain" #typeSelect>
            <mat-option *ngIf="!typeSelect.empty && typeSelect.panelOpen"></mat-option>
            <mat-option *ngFor="let blockchainType of blockchainTypes" [value]="blockchainType">
              {{blockchainType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/listScamFake/detail/0']">New</button>
        <button mat-raised-button (click)="back('/')" color="primary">Back</button>
      </mat-card-actions>
      <br />
      <mat-tab-group mat-stretch-tabs (selectedTabChange)="selectedTabChange($event)" [animationDuration]="'0'" [(selectedIndex)]="selectedIndex">
        <ng-container *ngFor="let tab of tabs; trackBy: trackByFn">
          <mat-tab [label]="tab" [aria-label]="tab">
            <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
              <mat-card-actions fxLayout="row" fxLayoutAlign="space-between end" style="padding-top: 1.5rem; padding-right: 3rem;">
                <mat-card style="width: 35%">
                  <ng-container *ngIf="selectedIndex === 0; else userLabels">
                    <p style="font-size: medium;">Nella seguente tabella sono presenti tutti i token inseriti nella Blacklist da Back Office manualmente oppure trasportate dalle azioni degli utenti.</p>
                  </ng-container>
                  <ng-template #userLabels>
                    <ng-container *ngIf="contentIsWhitelist; else usersBlacklistlist">
                      <p style="font-size: medium;">Nella seguente tabella sono presenti i Token inseriti in Blacklist dal Back Office ma che gli utenti hanno personalmente deciso di tirare fuori e visualizzare.</p>
                    </ng-container>
                  </ng-template>
                  <ng-template #usersBlacklistlist>
                    <p style="font-size: medium;">Nella seguente tabella sono presenti tutti i Token inseriti in Blacklist personalmente dagli utenti.</p>
                  </ng-template>
                </mat-card>
                <div>
                  <button *ngIf="selectedIndex === 1" mat-raised-button (click)="changeContent()" color="primary">
                    <ng-container *ngIf="!contentIsWhitelist; else whitelist">
                      Visualizza Whitelist Users
                    </ng-container>
                    <ng-template #whitelist>
                      Visualizza Blacklist Users
                    </ng-template>
                  </button>
                </div>
              </mat-card-actions>
              <ng-container *ngIf="parameters.dataSource.length; else noContent">
                
                <table class="pt-5" mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
                  matSortDisableClear [matSortActive]="parameters.sortField" [matSortDirection]="parameters.sortDirection" 
                  [dataSource]="parameters.dataSource">
        
                  <!-- title Column -->
                  <ng-container matColumnDef="tokenAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Token address </th>
                    <td mat-cell *matCellDef="let cryptoScamFake"> {{cryptoScamFake.tokenAddress}} </td>
                  </ng-container>
        
                  <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> nUtenti </th>
                    <td mat-cell *matCellDef="let cryptoScamFake"> {{cryptoScamFake.count}} </td>
                  </ng-container>

                  <!-- assetSymbol Column -->
                  <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Symbol </th>
                    <td mat-cell *matCellDef="let cryptoScamFake"> {{cryptoScamFake.symbol}} </td>
                  </ng-container>
        
                  <!-- type Column -->
                  <ng-container matColumnDef="blockchainType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Built on </th>
                    <td mat-cell *matCellDef="let cryptoScamFake"> {{cryptoScamFake.blockchainType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cryptoScamFake">
                      <button mat-mini-fab color="primary" [routerLink]="['/listScamFake/detail/', cryptoScamFake.id]">
                        <mat-icon>search</mat-icon>
                      </button>
                    </td>
                  </ng-container>
        
                  <ng-container matColumnDef="add">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cryptoScamFake">
                      <button mat-mini-fab color="primary" (click)="addCryptoToCoreBlacklist(cryptoScamFake)" [disabled]="cryptoScamFake.isCatalogPresent">
                        <mat-icon>add</mat-icon>
                      </button>
                    </td>
                  </ng-container>
        
                  <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let cryptoScamFake">
                      <button mat-mini-fab color="primary" (click)="removeCryptoFromCoreBlacklist(cryptoScamFake)" [disabled]="!cryptoScamFake.isCatalogPresent">
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)" [pageIndex]="parameters.page"></mat-paginator>
                </div>
              </ng-container>
              <ng-template #noContent>
                <h2>Nessuna mappatura trovata</h2>
              </ng-template>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-card-content>
</mat-card>