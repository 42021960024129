<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Smart Contract Address Mappings</h2>
    <p>Imposta il filtro di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">

      <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>

      <mat-form-field>
        <mat-select [(ngModel)]="parameters.builtOn" placeholder="Built on blockchain" #typeSelect>
          <mat-option *ngIf="!typeSelect.empty && typeSelect.panelOpen"></mat-option>
          <mat-option *ngFor="let blockchainType of blockchainTypes" [value]="blockchainType">
            {{blockchainType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/smartContractAddressMapping/detail/0']">New</button>
      <button mat-raised-button (click)="back('/')" color="primary">Back</button>
    </mat-card-actions>
    <br />

    <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
      <ng-container *ngIf="parameters.dataSource.length; else noContent">
        
        <h2>Lista Smart Contract Address Mappings</h2>
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
          matSortDisableClear [dataSource]="parameters.dataSource">

          <!-- id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.id}} </td>
          </ng-container>

          <!-- title Column -->
          <ng-container matColumnDef="smartContractAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Smart Contract Address </th>
            <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.smartContractAddress}} </td>
          </ng-container>

          <!-- assetSymbol Column -->
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Symbol </th>
            <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.assetDefinition.symbol}} </td>
          </ng-container>

          <!-- type Column -->
          <ng-container matColumnDef="builtOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Built on </th>
            <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.builtOn}} </td>
          </ng-container>

          <!-- type Column -->
          <ng-container matColumnDef="alias">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Alias </th>
            <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.alias}} </td>
          </ng-container>

          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let smartContractAddressMapping">
              <button mat-mini-fab color="primary" [routerLink]="['/smartContractAddressMapping/detail/', smartContractAddressMapping.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
            (page)="setPaginator($event)"></mat-paginator>
        </div>
      </ng-container>
      <ng-template #noContent>
        <h2>Nessuna mappatura trovata</h2>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>